 
import { Entity } from './entity';
import { Division } from './division';
import { District } from './district';

export class Municipality extends Entity {
    public id: number;
    public name: string;
    public division: Division;
    public district: District;

  }  