import { Component, OnInit, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { EventEmitter } from 'events';
import { DialogData } from 'src/app/entities/dialogData';
import { DialogCommunicator } from '../../../dialogs/dialog-communicator';
import { DivisionService } from 'src/app/services/division.service';
import { Municipality } from 'src/app/entities/municipality';
import { Division } from 'src/app/entities/division';
import { District } from 'src/app/entities/district';

@Component({
  selector: 'atom-municipality-dialog',
  templateUrl: './municipality-dialog.component.html',
  styleUrls: ['./municipality-dialog.component.css']
})
export class MunicipalityDialogComponent extends DialogCommunicator implements OnInit {
  text: string;
  emitter: EventEmitter;
  municipality: any;
  divisions  = []
  districts  = []
  selectDistricts: Array<District>
  selectDivisions: Array<Division>
  constructor(
    public dialogRef: MatDialogRef<MunicipalityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public sharedService: SharedService,
    public divisionService: DivisionService,
    public confirmationDialog: MatDialog) {
    super(dialogRef, sharedService)
    this.municipality = new Municipality()
    if (data){
      this.municipality = JSON.parse(JSON.stringify(data));
      this.municipality['class'] = 'MUNICIPALITY'
    }
    this.setEntity(this.municipality);
    this.setConfirmationDialog(this.confirmationDialog)

    this.divisionService.getList('dictionary/division').subscribe(divisions => {
      this.selectDivisions = this.divisions = divisions.data.data
    })

    this.divisionService.getList('dictionary/district').subscribe(districts => {
      this.selectDistricts = this.districts = districts.data.data
    })

  }

  selectChange = (event: any, field) => {
    switch (field) {
      case 'division':
        this.municipality.division = event.data.length===0?[]:[{ id: event.data[0].id, name: event.data[0].name, key: event.data[0].key}]
        break;
        case 'district':
          this.municipality.district = event.data.length===0?[]:event.data
          break;
      default:
        break;
    }
  };

  ngOnInit() { }
}